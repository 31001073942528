import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import useAudioRecorder from "@/components/Chat/hooks/useAudioRecorder";
import { AgentVoiceRecorder } from "./AgentVoiceRecorder";
import { blobToBase64 } from "@/utils/blob2Base64";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { getCustomVoiceTextQueryKey, useGetCustomVoiceText } from "@/data/queries/voice/useGetCustomVoiceText";
import { useCreateCustomVoice } from "@/data/mutations/voice/useCreateCustomVoice";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { cn } from "@/lib/utils";
import { VoiceProfileLanguage, VoiceProfileLanguageLabels } from "@/types/voice";
import { Select, SelectContent, SelectTrigger } from "@/components/ui/select";
import { SelectItem } from "@radix-ui/react-select";
import { Label } from "@radix-ui/react-label";

const conditionsText =
  "I hereby confirm that I have all necessary rights or consents to upload and clone these voice samples and that I will not use the platform-generated content for any illegal, fraudulent, or harmful purpose. I reaffirm my obligation to abide by AgentX AI’ Terms of Service and Privacy Policy.";

export const AgentVoiceCreateProfile = ({ onClose }: { onClose: () => void }) => {
  const {
    isRecording,
    isPlaying,
    hasAudio,
    recordingTime,
    currentTime,
    duration,
    startRecording,
    stopRecording,
    deleteRecording,
    play,
    pause,
    recordedAudio,
  } = useAudioRecorder({});

  const { getValues } = useAgentFormContext();
  const [agentId] = getValues(["_id", "publishedToCommunity"]);
  const [voiceProfileName, setVoiceProfileName] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState<VoiceProfileLanguage>(VoiceProfileLanguage.EN);
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [audioInvalidError, setAudioInvalidError] = useState<string | null>(null);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isConditionsInvalid, setIsConditionsInvalid] = useState(false);

  const handleCreateVoiceError = (message: string) => {
    deleteRecording();
    setAudioInvalidError(message);
  };

  const queryClient = useQueryClient();
  const {
    data: customVoiceTextData,
    isError,
    isLoading,
    isPending,
  } = useGetCustomVoiceText({ language: selectedLanguage });
  const { mutate: createCustomVoice, status: createCustomVoiceStatus } = useCreateCustomVoice(handleCreateVoiceError);
  const isCreating = createCustomVoiceStatus === "pending";

  // trigger validation
  useEffect(() => {
    if (recordedAudio) {
      setIsNameInvalid(voiceProfileName.trim() === "");
      setIsConditionsInvalid(!conditionsAccepted);
    }
  }, [recordedAudio, voiceProfileName, conditionsAccepted]);

  const closeAndRefresh = () => {
    void queryClient.invalidateQueries({ queryKey: getCustomVoiceTextQueryKey(selectedLanguage) });
    onClose();
  };

  const handleAddVoice = async () => {
    if (!recordedAudio || !customVoiceTextData?.id) {
      return;
    }
    const base64audio = await blobToBase64(recordedAudio.blob);

    createCustomVoice(
      {
        name: voiceProfileName,
        base64audio,
        textSampleId: customVoiceTextData?.id ?? "",
        botId: agentId,
        language: customVoiceTextData.language,
      },
      {
        onSuccess: closeAndRefresh,
      }
    );
  };

  const isSubmitEnabled =
    voiceProfileName.trim() !== "" &&
    hasAudio &&
    conditionsAccepted &&
    customVoiceTextData?.id &&
    recordedAudio &&
    selectedLanguage === customVoiceTextData.language &&
    !isLoading &&
    !isPending &&
    !isCreating &&
    !isError;

  return (
    <div className="fixed inset-0 z-50 flex flex-col">
      <div className="flex-1 cursor-pointer bg-transparent backdrop-blur-sm" onClick={closeAndRefresh}></div>

      <div className="z-50 flex h-auto border-t border-t-gray-300 bg-primary-50">
        <div className="mx-auto flex w-fit flex-col gap-4 px-4 pb-8 pt-4 md:w-[790px] ">
          <h2 className="w-full text-center text-xl font-semibold">Create a Voice Profile</h2>
          <Input
            label="Name"
            value={voiceProfileName}
            onChange={e => setVoiceProfileName(e.target.value)}
            onFocus={() => setIsNameInvalid(false)}
            placeholder="Give the voice a name"
            inputClassName={cn("h-10", { "border-red-500": isNameInvalid })}
            required
          />
          {isNameInvalid ? <p className="-my-3 text-xs text-red-500">Name is required</p> : <div className="-my-1" />}

          <div className="flex flex-col gap-1.5">
            <Label htmlFor="language" className="text-sm">
              Languge
            </Label>
            <Select
              onValueChange={(value: VoiceProfileLanguage) => setSelectedLanguage(value)}
              value={selectedLanguage}
            >
              <SelectTrigger id="custom_voice_language" className="mb-2 w-full">
                {VoiceProfileLanguageLabels[selectedLanguage as keyof typeof VoiceProfileLanguageLabels]}
              </SelectTrigger>
              <SelectContent className="select-content">
                {Object.values(VoiceProfileLanguage).map(language => (
                  <SelectItem key={language} value={language} className="px-2 hover:bg-primary-100">
                    {VoiceProfileLanguageLabels[language as keyof typeof VoiceProfileLanguageLabels]}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <p className="text-sm">Record voice</p>
          <div
            className={cn("flex h-auto w-full flex-col justify-between gap-1 rounded-md border bg-white p-3", {
              "border-red-500": !!audioInvalidError,
              "border-gray-300": !audioInvalidError,
            })}
            onClick={() => setAudioInvalidError(null)}
          >
            {isLoading || isPending ? (
              <LoadingSpinner className="flex h-[82px] items-center justify-center" />
            ) : isError ? (
              <div className="align-center flex h-[82px] flex-col items-center justify-center">
                Error loading custom voice text
              </div>
            ) : (
              <div className="flex h-auto flex-col justify-between xs:h-[82px]">
                <p className="p-1 text-sm">{customVoiceTextData?.text}</p>
                <p className="px-1 text-right text-xs text-gray-500 sm:py-1">- {customVoiceTextData?.author}</p>
              </div>
            )}
            {audioInvalidError ? (
              <p className="-my-0.5 px-0.5 text-xs text-red-500">{audioInvalidError}</p>
            ) : (
              <div className="h-3" />
            )}
            <AgentVoiceRecorder
              isRecording={isRecording}
              isPlaying={isPlaying}
              hasAudio={hasAudio}
              recordingTime={recordingTime}
              currentTime={currentTime}
              duration={duration}
              startRecording={startRecording}
              stopRecording={stopRecording}
              deleteRecording={deleteRecording}
              play={play}
              pause={pause}
            />
          </div>
          <div
            className={cn("mt-2 flex cursor-pointer items-center gap-2 text-[10px]", {
              "rounded-md border-red-500": isConditionsInvalid,
            })}
            onClick={() => {
              setConditionsAccepted(!conditionsAccepted);
              setIsConditionsInvalid(false);
            }}
          >
            <Checkbox size="sm" checked={conditionsAccepted} className="h-4 w-4 rounded-sm p-[1px]" />
            <div>{conditionsText}</div>
          </div>
          {isConditionsInvalid ? <p className="-my-2 text-xs text-red-500">You must accept the conditions</p> : <div />}

          <Button
            className="text-md flex h-12 w-full text-sm"
            variant="primary"
            onClick={handleAddVoice}
            disabled={!isSubmitEnabled}
          >
            {isCreating ? "Creating..." : "Add voice"}
          </Button>
          <Button className="text-md flex h-12 w-full text-sm" variant="tertiary" onClick={closeAndRefresh}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

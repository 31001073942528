import { useEffect, useState } from "react";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { useDebounceValue } from "usehooks-ts";

interface AgentVoiceSpeedPickerProps {
  quantity: number;
  onChangeQuantity: (newQuantity: number) => void; // Backend callback
  disabled: boolean;
}

export const AgentVoiceSpeedPicker: React.FC<AgentVoiceSpeedPickerProps> = ({
  quantity,
  onChangeQuantity,
  disabled,
}) => {
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const [debouncedQuantity] = useDebounceValue(localQuantity, 500);

  useEffect(() => {
    if (debouncedQuantity !== quantity) {
      onChangeQuantity(debouncedQuantity);
    }
  }, [debouncedQuantity, quantity, onChangeQuantity]);

  const handleDecrease = () => {
    const newQuantity = Math.max(0, localQuantity - 0.1);
    setLocalQuantity(parseFloat(newQuantity.toFixed(1)));
  };

  const handleIncrease = () => {
    const newQuantity = Math.min(2, localQuantity + 0.1);
    setLocalQuantity(parseFloat(newQuantity.toFixed(1)));
  };

  return (
    <div className="-mr-2 flex items-center gap-0.5">
      <IconButton
        className="!text-neutral-300"
        variant="tertiary"
        icon={<Icons.Minus />}
        size="tiny"
        onClick={handleDecrease}
        disabled={disabled || localQuantity <= 0.1}
      />
      <div className="min-h-9 min-w-9 rounded-md border border-neutral-300 bg-white p-2 text-center text-sm font-semibold">
        {localQuantity.toFixed(1)}
      </div>
      <IconButton
        variant="tertiary"
        icon={<Icons.Plus />}
        size="tiny"
        onClick={handleIncrease}
        disabled={disabled || localQuantity >= 2}
      />
    </div>
  );
};
